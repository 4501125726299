<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				商品分类缓存
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
				</span>
			</div>
		</div>
		
		<div style="padding: 10px 20px 20px 20px;">
			<el-button type="primary"  @click="CacheAll()">更新全量分类缓存</el-button>
			<p>此处更新的是全部分类数据缓存</p>
		</div>
		

	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'GoodsCategoryInfo',
	  props: {
	  },
	  data() {
	      return {
			  GoodsCategory:null,
			  FatherList:[],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
		
	  },
	  methods:{
			CacheAll(){
				let that = this
				setTimeout(function(){
					that.$message.success('更新完成')
				},500)
			},
			CachePcHome(){
				let data = {Service:'Goods',Class: 'GoodsCategory',Action: 'CachePcHome'}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
				})
			},
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Brand .List{
	margin-top: 20px;	
}
.Brand .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Brand .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Brand .List .One li  em{
	flex: 1;
}
.Brand .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}


.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }

</style>
